import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpImplService } from '../http/http-impl.service';

@Injectable({
  providedIn: 'root'
})
export class PrivateService {

  privateUrl = `${environment.apiUrl}private`


  constructor(private http: HttpImplService) { }

  alterarSenha(senhaAtual: string, novaSenha: string): Observable<any> {
      return this.http.put(`${this.privateUrl}/alterarSenha`, [senhaAtual, novaSenha]);
  }

  getTermoVigente(): Observable<any> {
    return this.http.get(`${this.privateUrl}/termoVigente`);
  }

  aceitarTermoVigente(idTermo: number) {
      return this.http.put(`${this.privateUrl}/aceitarTermo/${idTermo}`, null);
  }

  getMeusDados(): Observable<any> {
    return this.http.get(`${this.privateUrl}/meusDados`);
  }

  alterarMeusDados(campo: string, novoValor: string): Observable<any> {
    return this.http.put(`${this.privateUrl}/meusDados`, [campo, novoValor]);
  }
}
